import React from "react";
import { motion } from "framer-motion";

export default function FullWidthBanner() {
  return (
    <motion.div
      className="relative h-96 bg-cover bg-center flex items-center justify-center"
      style={{ backgroundImage: "url('/HairBanner.png')" }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="absolute inset-0 bg-black opacity-30" />
      <div className="relative text-white text-center">
        <h2 className="text-4xl font-bold mb-4">Summer Collection</h2>
        <p className="text-xl mb-6">Discover our new hair color treatments</p>
        <motion.button
          className="px-6 py-3 bg-white text-blue-300 rounded-full font-semibold hover:bg-blue-100 transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Shop Now
        </motion.button>
      </div>
    </motion.div>
  );
}
