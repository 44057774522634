import React from 'react';

const ConstructionBanner = () => {
  return (
    <div className="bg-black text-white py-2 px-4 text-center fixed top-0 left-0 right-0 z-50">
      <p className="text-sm font-medium">
        THIS WEBSITE IS UNDER CONSTRUCTION
      </p>
    </div>
  );
};

export default ConstructionBanner;