import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const features = [
  {
    title: "Vibrant Colors",
    description: "Explore our range of bold and beautiful hair color treatments.",
    image: "/hairSquare1.png",
  },
  {
    title: "Long-lasting Wear",
    description: "Our formulas stay longer so you can enjoy more time to be you.",
    image: "/hairSquare2.png",
  },
  {
    title: "Nourishing Ingredients",
    description: "Packed with vitamins and minerals for that healthy hair shine.",
    image: "/hairSquare3.png",
  },
];

const FeatureCard = ({ feature }) => (
  <div className="bg-white rounded-lg shadow-xl p-6 flex flex-col">
    <img
      src={feature.image}
      alt={feature.title}
      className="w-full h-48 object-cover rounded-lg mb-4"
    />
    <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
    <p className="text-gray-600 flex-grow">{feature.description}</p>
  </div>
);

const FeatureSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
    }, 3000); // Change slide every 3 seconds
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="bg-gradient-to-r from-blue-100 to-purple-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">
          Why Choose Our Hair Products?
        </h2>

        {/* Desktop version */}
        <div className="hidden md:grid grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} feature={feature} />
          ))}
        </div>

        {/* Mobile version */}
        <div className="md:hidden relative h-96 overflow-hidden">
          <AnimatePresence initial={false}>
            <motion.div
              key={currentIndex}
              className="absolute inset-0 flex items-center justify-center"
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "-100%" }}
              transition={{ type: "tween", duration: 0.5 }}
            >
              <FeatureCard feature={features[currentIndex]} />
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default FeatureSlider;
