import React from "react";
import { motion } from "framer-motion";

const images = [
  { src: "/collage1.png", size: "col-span-2 row-span-2" },
  { src: "/collage2.png", size: "col-span-1 row-span-1" },
  { src: "/collage3.png", size: "col-span-1 row-span-1" },
  { src: "/collage4.png", size: "col-span-1 row-span-2" },
  { src: "/collage5.png", size: "col-span-1 row-span-1" },
];

export default function ImageCollage() {
  return (
    <div className="container mx-auto py-16">
      <div className="grid grid-cols-3 gap-4">
        {images.map((image, index) => (
          <motion.div
            key={index}
            className={`relative ${image.size} overflow-hidden`}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <img
              src={image.src}
              alt={`Collage image ${index + 1}`}
              className="object-cover w-full h-full"
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
}